import lc_other from '@img/lc_other.webp';
import placeholderOthers from '@img/placeholder_others.webp';
import {
    iconStoreMapping, storeValidValues, storePlaceholderMap, categoryImages,
} from './constants';


export const  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const distance = (lat1, lon1, lat2, lon2) => {
    const earthhRadius = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const distanceByHaversine =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(distanceByHaversine), Math.sqrt(1 - distanceByHaversine));
    const distance = earthhRadius * c;
    return distance;
}

export const decodeJwt = (token) => {
    const parts = token.split(".");
    if (parts.length !== 3) {
        throw new Error("Invalid token format");
    }
    const header = JSON.parse(atob(parts[0]));
    const payload = JSON.parse(atob(parts[1]));
    return { header, payload };
}

export const ProcesEncrypt = (text,shift) =>{
    let encryptedText = "";
    for (let i = 0; i < text.length; i++) {
        let char = text[i];
        if (char.match(/[a-z]/i)) {
            let code = text.charCodeAt(i);
            if (code >= 65 && code <= 90) {
                char = String.fromCharCode(((code - 65 + shift) % 26) + 65);
            } else if (code >= 97 && code <= 122) {
                char = String.fromCharCode(((code - 97 + shift) % 26) + 97);
            }
        }
        encryptedText += char;
    }
    return encryptedText;
}

export const getPlaceholder = (value) => {
    return storePlaceholderMap[value] || placeholderOthers;
};

export const getColor = (value) => {
    return storeValidValues.includes(value) ? value : 'dog-other';
};

export const getIconMaker = (value) => {
    return iconStoreMapping[value] || lc_other;
};

export const getImageCategory = (value) =>{
    const transformedValue = value.replace(/-/g, '_');
    const key = `c_${transformedValue}`;
    return categoryImages[key];
}

export const orderStoreByLocation = (categories, lat, lng) =>{
    let stores = []
    const storesByCategories = categories.map(category => {
        return {
            stores: category.stores.map(store =>{
                return {
                    ...store,
                    categoryValue: category.value,
                    categoryName: category.name,
                    distanceKM: distance(lat, lng, store.lat, store.lng),
                    iconMarker: getIconMaker(category.value),
                    iconlist: getImageCategory(category.value),
                }
            })
        }
    });
    stores = storesByCategories.flatMap(obj => obj.stores);
    stores.sort((a, b) => a.distanceKM - b.distanceKM);
    return stores;
}

export const ListUniqueStores = (stores) => {
    const storeMap = new Map();
    stores.forEach((store) => {
        if (!storeMap.has(store.id)) {
            storeMap.set(store.id, {
                ...store,
                iconlist: new Set([store.iconlist])
            });
        } else {
            storeMap.get(store.id).iconlist.add(store.iconlist);
        }
    });

    const uniqueData = [];
    const seenIds = new Set();

    stores.forEach((store) => {
        if (!seenIds.has(store.id)) {
            const storeData = storeMap.get(store.id);
            storeData.iconlist = Array.from(storeData.iconlist);
            uniqueData.push(storeData);
            seenIds.add(store.id);
        }
    });

    return uniqueData;
};

export const FilterTypeStore = (stores, options) =>{
    const uniqueTypes = [...new Set(stores.map(store => store.type))];
    return options.filter(option => uniqueTypes.includes(option.value));
}

export const getStoresByUserLocation = (stores, userLocation) =>{
    let storesByUserLocation = stores.map(store => {
        return {
            ...store,
            distanceKM: distance(userLocation.lat, userLocation.lng, store.lat, store.lng),
        };
    });
    storesByUserLocation.sort((a, b) => a.distanceKM - b.distanceKM);
    return storesByUserLocation
}

export const getListStoreFavorites = (stores, storefavorite) =>{
    return stores.map((store)=>{
        let isFavorite;
        if(store.id === storefavorite.storeId){
            isFavorite = storefavorite.status
        }else{
            isFavorite = store.isFavorite
        }
        return{
            ...store,
            isFavorite
        }
    })
}

export const getVisibleMarkers = (stores, bounds) =>{
    if (typeof bounds.getNorthEast !== 'function' || typeof bounds.getSouthWest !== 'function') {
        return stores.filter(store =>
            parseFloat(store.lat) <= parseFloat(bounds.nw.lat) &&
            parseFloat(store.lat) >= parseFloat(bounds.se.lat) &&
            parseFloat(store.lng) >= parseFloat(bounds.nw.lng) &&
            parseFloat(store.lng) <= parseFloat(bounds.se.lng)
        );
    }else{
        const NEPoint = bounds.getNorthEast();
        const SWPoint = bounds.getSouthWest();
        const neLat = NEPoint.lat();
        const neLng = NEPoint.lng();
        const swLat = SWPoint.lat();
        const swLng = SWPoint.lng();
        return stores.filter(store =>
            parseFloat(store.lat) <= neLat &&
            parseFloat(store.lat) >= swLat &&
            parseFloat(store.lng) >= swLng &&
            parseFloat(store.lng) <= neLng
        );
    }
}

export const isValidEmail=(email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}
